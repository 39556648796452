import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import Layout from '../components/Layout';
import Title from '../components/Title';
import { green, red, desktop, tablet } from '../constants';
import SEO from '../components/seo';

export const query = graphql`
  {
    prismic {
      about_us(uid: "about", lang: "en-gb") {
        title
        description
        centre_align_description
        size
        personnel {
          image
          name
          role
          email
        }
      }
    }
  }
`;

const fontSizes = {
  Normal: { mobile: 1, tablet: 1.25, desktop: 1.5 },
  Large: { mobile: 1.25, tablet: 1.5, desktop: 1.75 },
  Small: { mobile: 1, tablet: 1, desktop: 1.25 }
};

const Intro = styled.div`
  & p {
    font-size: ${({ size }) => fontSizes[size]?.mobile}rem;
    line-height: 1.5;
    color: ${green};
    font-weight: 300;
    text-align: ${({ centre }) => centre ? 'center' : 'left'};

    @media (min-width: ${tablet}) {
      font-size: ${({ size }) => fontSizes[size]?.tablet}rem;
    }

    @media (min-width: ${desktop}) {
      font-size: ${({ size }) => fontSizes[size]?.desktop}rem;
    }

    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Image = styled.img`
  max-width: 16rem;
`;

const Personnel = styled.section`
  margin: 3rem auto;

  @media (min-width: ${tablet}) {
    margin: 5rem auto;
    font-size: 1.25rem;
    text-align: center;
  }

  ${({ numberOfPeople }) => numberOfPeople > 1 && `
    @media (min-width: ${desktop}) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
  `}
`;

const Name = styled.h3`
  font-size: 1.5rem;
  line-height: 1.5;
  color: ${red};
  font-weight: 800;
  margin-top: 1rem;
`;

const Role = styled.div`
  font-size: 1.25rem;
  line-height: 1.5;
  color: ${green};
  font-weight: 300;
`;

const Email = styled.a`
  font-size: 1rem;
  line-height: 1.5;
  color: ${green};
  font-weight: 300;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Overlay = styled.div`
  display: none;
  position: absolute;
  background: white;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  color: ${green};
`;

const Belowverlay = styled.div`
  @media (min-width: ${desktop}) {
    display: none;
  }
`;

const Person = styled.div`
  text-align: center;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover ${Overlay} {
    display: flex;
  }
`;

const PersonContainer = styled.div`
  text-align: center;
  margin-bottom: 2.5rem;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ numberOfPeople }) => numberOfPeople > 1 && `
    @media (min-width: ${desktop}) {
      margin: 1.5rem;
      flex-basis: auto;
    }
  `}
`;

const About = ({ data }) => {
  const { title, description, personnel, centre_align_description, size = 'Normal' } = data.prismic.about_us;

  const numberOfPeople = personnel.length;

  return (
    <Layout>
      <SEO title="About" />
      {title && <Title>{RichText.asText(title)}</Title>}
      <Intro centre={centre_align_description} size={size}>
        {description && <RichText render={description} />}
      </Intro>
      <Personnel numberOfPeople={numberOfPeople}>
        {personnel.map(({ image, name, role, email }, key) => (
          <PersonContainer key={key} numberOfPeople={numberOfPeople}>
            {name && <Person key={RichText.asText(name)}>
              {image && <Image src={image.url} alt={image.alt} />}
              <Overlay>
                {role && <Role>{RichText.asText(role)}</Role>}
                {email && <Email href={`mailto:${RichText.asText(email)}`}>
                  {RichText.asText(email)}
                </Email>}
              </Overlay>
            </Person>}
            {name && <Name>{RichText.asText(name)}</Name>}
            <Belowverlay>
              {role && <Role>{RichText.asText(role)}</Role>}
              {email && <Email href={`mailto:${RichText.asText(email)}`}>
                {RichText.asText(email)}
              </Email>}
            </Belowverlay>
          </PersonContainer>
        ))}
      </Personnel>
    </Layout>
  );
};

export default About;
